.App {
  text-align: center;
}

.App-header {
  position: absolute;
  display: flex;
  top: 46vh;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(52, 46, 56);
  color: rgb(238, 210, 255);
  font-size: calc(10px + 2vmin);
  padding-top: 80px;
  padding-bottom: 60px;
  line-height: 45px;
}

.App-header a {
  font-size: 20px;
}

a {
  color: rgb(238, 210, 255);
  text-decoration: none;
}

a:hover {
  cursor: pointer;
}

form {
  width: 100%;
  position: relative;
}

.Page {
  background-color: rgb(52, 46, 56);
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* font-size: calc(10px + 2vmin); */
  color: rgb(238, 210, 255);
}

.PageTitle {
  margin-top: 3px;
  font-size: 23px;
}

.footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  margin: auto, 0px;
  text-align: center;
  align-items: center;
  min-width: 100vw;
  background-color: rgb(52, 46, 56);
  padding: 14px 0px;
}

.butContain {
  position: relative;
  display: inline-flex;
  width: 190px;
  top: 40px;
  left: 0%;
  margin-bottom: 70px;
}

.resultGA {
  position: relative;
  display: inline-flex;
  top: 50px;
  left: 0%;
  font-size: 24px;
  color: rgb(255, 199, 246);
}

.resultEDD {
  position: relative;
  display: inline-flex;
  top: 70px;
  left: 0%;
  margin-bottom: 140px;
  font-size: 18px;
  color: rgb(255, 199, 246);
}

.backBut, .clearBut, input[type=number] {
  background: rgb(238, 210, 255);
  color: rgb(52, 46, 56);
  border: 1px solid rgb(52, 46, 56);
  box-shadow: 0px 0px 2px rgba(43, 1, 56, 0.6) inset;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 6px;
  padding: 7px 12px;
}

.dayInput, .monthInput {
  width: 2.5em;
}

.yearInput {
  width: 3.5em;
}

.backBut {
  position: relative;
  background: rgb(255, 199, 246);
  font-size: 17px;
  border-radius: 8px;
  padding: 8px 14px;
  bottom: 0px;
}

.clearBut {
  background: rgb(255, 199, 246);
  font-size: 16px;
  border-radius: 8px;
  padding: 8px 14px;
  position: absolute;
  left: 0px;
}

.subBut {
  background: rgb(191, 14, 161);
  color: rgb(252, 233, 255);
  border: 1px solid rgb(52, 46, 56);
  box-shadow: 0px 0px 1px rgba(43, 1, 56, 0.6) inset;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 14px;
  position: absolute;
  right: 0px;
}

.backBut:active, .clearBut:active, .subBut:active {
  box-shadow: 0px 0px 4px rgba(43, 1, 56, 0.7) inset;
}

input[type=number]:focus {
  box-shadow: 0px 0px 3px rgba(43, 1, 56, 0.7) inset;
}

@font-face {
  font-family: 'BlackLarch';
  src: url('/public/blacklarch_personal_use-webfont.woff')  format('woff');
}
  
.logo {
  color: rgb(255, 165, 227);
  font-family: 'BlackLarch';
  font-style: normal;
  font-weight: normal;
  font-size: 56px;
}

.PageLogo {
  color: rgb(255, 165, 227);
  font-family: 'BlackLarch';
  font-style: normal;
  font-weight: normal;
  font-size: 46px;
  margin-top: 60px;
}

label {
  font-size: 16px;
}

.aboutLabel {
  position: relative;
  color: rgb(255, 209, 252);
  font-size: 12px;
  border-radius: 10px;
  padding: 8px 14px;
  bottom: 0px;
  letter-spacing: 2px;
}

.tinux {
  color: rgb(255, 195, 251);
  font-size: 12.5px;
}

::-moz-selection {
  background: rgb(255, 176, 234);
}
::selection {
  background: rgb(255, 176, 234);
}
